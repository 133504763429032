.MuiAppBar-root {
  margin-bottom: 10px;
  box-shadow: none !important;

  .MuiTabs-root {
    min-height: 25px !important;

    .MuiTab-root {
      min-height: auto;
    }
  }

  .MuiTabs-flexContainer {
    overflow-x: scroll;

    .MuiTab-root {
      padding: 5px 10px;
    }

    .MuiTab-wrapper {
      font-size: 12px;
      color: black;
    }
  }
}

