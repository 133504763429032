@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap);
#cookie-consent
{
    position: fixed;
    z-index: 9999999;
    bottom: 0;
    left: 0;
    width: 100%;
    background:#E5412C;
    color: #fff;
    padding: 5px;
}
#cookie-consent a
{
    color: #197bba;
    cursor: pointer;
}
#cookie-consent-title
{
    padding: 5px 20px 5px 20px;
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    display: block;
}
.cookie-consent-text
{
    padding: 0 5px 0 5px;
    line-height: 150%;
    margin: 0;
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    float: left;
    width: 95%;
}
#cookie-consent-close
{
    white-space: nowrap;
    text-decoration: none;
    background:#f5f5f5;
    padding: 5px 10px;
    color: #E5412C;
    border: 2px solid #fff;
    float: left;
    margin: 5px 5px;
    cursor: pointer;
}

.cookie-consent-visible
{
    display: block;
}

.cookie-consent-hidden
{
    display: none;
}
.MuiSelect-select.MuiSelect-select :focus{background-color:#fff}.MuiSelect-select.MuiSelect-select :selection{background-color:#fff}.MuiSelect-select.MuiSelect-select :after{background-color:#fff}.MuiSelect-select.MuiSelect-select :active{background-color:#fff}.MuiSelect-select:focus{background-color:#fff}
html, body {
  height: 100%;
  margin: 0px;
  background-color: #f5f5f5;
}

.content {
  min-height: calc(100vh - 100px);
}

.theme-background-color
{
  background-color: #f5f5f5;
}
.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,.ag-theme-material .ag-layout-print .ag-center-cols-clipper,.ag-theme-material .ag-layout-print .ag-center-cols-container{min-height:40px}
.MuiAppBar-root{margin-bottom:10px;box-shadow:none !important}.MuiAppBar-root .MuiTabs-root{min-height:25px !important}.MuiAppBar-root .MuiTabs-root .MuiTab-root{min-height:auto}.MuiAppBar-root .MuiTabs-flexContainer{overflow-x:scroll}.MuiAppBar-root .MuiTabs-flexContainer .MuiTab-root{padding:5px 10px}.MuiAppBar-root .MuiTabs-flexContainer .MuiTab-wrapper{font-size:12px;color:#000}
@media (max-width:319px) 
{
    #mb-app-last-update{
        font-size: 0.6rem;
    }
}
html, body, table 
{
    background-color: white;
}

body 
{
    font-family: 'Helvetica Neue, Helvetica, Arial';
    line-height: 1.5;
    color: '#3b3b3b';
}

.classic-table
{
    border: 1px solid #dee2e6;  
    border-collapse: collapse;
}

.classic-table thead
{
    box-sizing: border-box;
}

.classic-table th
{
    color: #fff;
    background-color: #2980b9;
    border-color: #2980b9;
    border-bottom-width: 2px;
    border-bottom: 2px solid #dee2e6;
}

.classic-table th, 
.classic-table td 
{
    border: 1px solid #dee2e6;
    padding: .25rem 0.75rem;
    white-space: nowrap;
}

.classic-table tbody tr:nth-of-type(odd) 
{
    background-color: rgba(0, 0, 0, 0.05);
}

.classic-view-report-header
{
    text-align: center;
}

.classic-view-report-header h1
{
    font-size: 18px;
}

.classic-view-report-descriptions
{
    text-align: center;  
}

.classic-view-report-data
{
    margin: 0;
    padding: 0;
}
