html, body, table 
{
    background-color: white;
}

body 
{
    font-family: 'Helvetica Neue, Helvetica, Arial';
    line-height: 1.5;
    color: '#3b3b3b';
}

.classic-table
{
    border: 1px solid #dee2e6;  
    border-collapse: collapse;
}

.classic-table thead
{
    box-sizing: border-box;
}

.classic-table th
{
    color: #fff;
    background-color: #2980b9;
    border-color: #2980b9;
    border-bottom-width: 2px;
    border-bottom: 2px solid #dee2e6;
}

.classic-table th, 
.classic-table td 
{
    border: 1px solid #dee2e6;
    padding: .25rem 0.75rem;
    white-space: nowrap;
}

.classic-table tbody tr:nth-of-type(odd) 
{
    background-color: rgba(0, 0, 0, 0.05);
}

.classic-view-report-header
{
    text-align: center;
}

.classic-view-report-header h1
{
    font-size: 18px;
}

.classic-view-report-descriptions
{
    text-align: center;  
}

.classic-view-report-data
{
    margin: 0;
    padding: 0;
}